<template>
  <div
    id="app"
    :class="{ appBackground: backgroundActive, app: !backgroundActive }"
  >
    <div
      v-if="
        currentUser &&
        $store.state.userInfo &&
        this.$route.name !== 'complete-signup' &&
        this.$route.name !== '2021-scaleups-south-korea' &&
        this.$route.name !== '2021-scaleups-silicon-valley'
      "
      class="account d-none d-sm-block"
    >
      <b-dropdown size="md" variant="link" toggle-class="text-decoration-none">
        <template #button-content>
          <b-avatar variant="primary" size="sm" class="mr-1"></b-avatar>
          Hi, {{ $store.state.userInfo.firstName || "" }}
        </template>
        <b-dropdown-item
          v-if="$store.state.isAdmin || $store.state.isMtb"
          to="/dashboard"
          >Dashboard</b-dropdown-item
        >

        <b-dropdown-item @click="logout">Logout</b-dropdown-item>
      </b-dropdown>
    </div>

    <div id="page-content">
      <img
        v-if="
          this.$route.name !== '2021-scaleups-south-korea' &&
          this.$route.name !== '2021-scaleups-silicon-valley'
        "
        alt="MTB Research"
        class="mt-4"
        src="./assets/mtb-research-logo.png"
        height="110px"
        @click="redirectToHome()"
      />

      <div v-if="$route.path !== '/dashboard'">
        <div
          class="justify-content-center navbar"
          v-if="currentUser && this.$route.meta.navbar"
        >
          <b-navbar toggleable="md">
            <b-navbar-toggle
              class="toggle"
              target="nav-collapse"
            ></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mx-2" pills>
                <b-nav-item class="mx-2" to="/reports">Reports</b-nav-item>
                <b-nav-item class="mx-2" to="/directories"
                  >Directories</b-nav-item
                >
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </div>

      <router-view />
    </div>

    <div
      v-if="
        this.$route.name !== '2021-scaleups-south-korea' &&
        this.$route.name !== '2021-scaleups-silicon-valley'
      "
      class="scroll-up d-none d-md-block"
    >
      <b-icon
        class=""
        style="width: 60px; height: 60px; color: 051930"
        @click="scrollTop"
        icon="arrow-up-circle"
      ></b-icon>
    </div>

    <Footer
      v-if="
        this.$route.name !== '2021-scaleups-south-korea' &&
        this.$route.name !== '2021-scaleups-silicon-valley'
      "
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import Footer from "./components/Footer";
import util from "./support/util";
require("firebase/auth");
require("firebase/firestore");

export default {
  components: { Footer },
  data() {
    return {};
  },
  computed: {
    backgroundActive() {
      if (this.$route.name === "Signup" || this.$route.name === "Login") {
        return true;
      } else {
        return false;
      }
    },
    routeName() {
      return this.$route.name;
    },
    currentUser() {
      return firebase.auth().currentUser;
    },
  },
  methods: {
    redirectToHome() {
      this.$router.push("/reports");
    },
    logout() {
      this.$store.commit("LOGOUT");
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: "Login" });
        });
    },
  },
  created() {
    this.scrollTop = util.scrollTop;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch("setUserInfo");
        this.$store.dispatch("setUserClaims");
        this.$store.dispatch("loadReports").catch((e) => {
          console.log(e);
        });
        this.$store.dispatch("loadDirectories").catch((e) => {
          console.log(e);
        });
      }
    });
  },
};
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.appBackground {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url("/background.jpg");
  background-size: cover;
}
#page-content {
  position: relative;
  min-height: 100vh;
}
.scroll-up {
  position: absolute;
  padding: 20px;
  padding-top: 30px;
  right: 4vw;
}
.account {
  position: absolute;
  top: 3vh;
  right: 3vw;
  color: #0275d8;
  z-index: 1 !important;
}
.navbar {
  font-size: 20px;
  font-weight: 500;
}
.toggle {
  display: block;
}
.navbar-toggler {
  margin: 0 auto;
}
.router-link-active {
  color: #037bfe !important;
}
</style>
