var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ appBackground: _vm.backgroundActive, app: !_vm.backgroundActive },attrs:{"id":"app"}},[(
      _vm.currentUser &&
      _vm.$store.state.userInfo &&
      this.$route.name !== 'complete-signup' &&
      this.$route.name !== '2021-scaleups-south-korea' &&
      this.$route.name !== '2021-scaleups-silicon-valley'
    )?_c('div',{staticClass:"account d-none d-sm-block"},[_c('b-dropdown',{attrs:{"size":"md","variant":"link","toggle-class":"text-decoration-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"}}),_vm._v(" Hi, "+_vm._s(_vm.$store.state.userInfo.firstName || "")+" ")]},proxy:true}],null,false,2077246489)},[(_vm.$store.state.isAdmin || _vm.$store.state.isMtb)?_c('b-dropdown-item',{attrs:{"to":"/dashboard"}},[_vm._v("Dashboard")]):_vm._e(),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("Logout")])],1)],1):_vm._e(),_c('div',{attrs:{"id":"page-content"}},[(
        this.$route.name !== '2021-scaleups-south-korea' &&
        this.$route.name !== '2021-scaleups-silicon-valley'
      )?_c('img',{staticClass:"mt-4",attrs:{"alt":"MTB Research","src":require("./assets/mtb-research-logo.png"),"height":"110px"},on:{"click":function($event){return _vm.redirectToHome()}}}):_vm._e(),(_vm.$route.path !== '/dashboard')?_c('div',[(_vm.currentUser && this.$route.meta.navbar)?_c('div',{staticClass:"justify-content-center navbar"},[_c('b-navbar',{attrs:{"toggleable":"md"}},[_c('b-navbar-toggle',{staticClass:"toggle",attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"mx-2",attrs:{"pills":""}},[_c('b-nav-item',{staticClass:"mx-2",attrs:{"to":"/reports"}},[_vm._v("Reports")]),_c('b-nav-item',{staticClass:"mx-2",attrs:{"to":"/directories"}},[_vm._v("Directories")])],1)],1)],1)],1):_vm._e()]):_vm._e(),_c('router-view')],1),(
      this.$route.name !== '2021-scaleups-south-korea' &&
      this.$route.name !== '2021-scaleups-silicon-valley'
    )?_c('div',{staticClass:"scroll-up d-none d-md-block"},[_c('b-icon',{staticStyle:{"width":"60px","height":"60px","color":"051930"},attrs:{"icon":"arrow-up-circle"},on:{"click":_vm.scrollTop}})],1):_vm._e(),(
      this.$route.name !== '2021-scaleups-south-korea' &&
      this.$route.name !== '2021-scaleups-silicon-valley'
    )?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }